<template>
  <div class="category-form">
      <portlet :title="portletTitle">
        <template v-slot:body>
          <b-form ref="category" @submit.stop.prevent="onSubmit" @reset="onReset">
            <b-form-row>
              <b-col sm="6" md="2">
                <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
                  <b-form-checkbox id="is_show" switch v-model="formData.is_show"/>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="2">
                <b-form-group id="input-group-in_home" label="باشبەتتە كۆرۈنەمدۇ ؟" label-for="in_home">
                  <b-form-checkbox id="in_home" switch v-model="formData.in_home"/>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col sm="6" md="4">
                <b-form-group id="input-group-8" label="تۈر ماۋزوسى" label-for="title" description="نۆۋەتتىكى تۈرنىڭ ماۋزوسىنى كىرگۈزۈڭ">
                  <b-form-input id="title" v-model="formData.title" type="text" :state="validateState('title')" aria-describedby="title-live-feedback" placeholder="تۈر ماۋزوسىنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="title-live-feedback">
                    تۈر ماۋزوسىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="4">
                <b-form-group id="input-group-background" label="دېكوراتسىيە رەڭ نۇمۇرى" label-for="background">
                <b-input-group>
                  <b-form-input id="background" placeholder="سىنبەلگە دېكوراتسىيە رەڭگىنى تاللاڭ" style="direction: ltr" v-model="formData.background" />
                  <b-input-group-append>
                    <b-button class="d-flex align-items-center justify-content-center">
                      <colorPicker ref="colorPicker" v-model="formData.background || ''" @change="changeBackground" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="4">
                <b-form-group id="input-group-9" label="تۈر تەرتىبى" label-for="sort" description="نۆۋەتتىكى تۈرنىڭ تەرتىبىنى كىرگۈزۈڭ">
                  <b-form-input min="0" style="width: 100%;" id="sort" v-model="formData.sort" type="number" placeholder="تۈرنىڭ تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col sm="12" md="4">
                <b-form-group id="input-group-icon" label="تۈر سىنبەلگىسى" description="نۆۋەتتىكى تۈرنىڭ سىنبەلگە رەسىمىنى تاللاپ يوللاڭ">
                  <image-uploader @success="onUploadSuccess" :img-url="oldImage" :upload-form="{strategy: 'category'}" />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-button id="submit" type="submit" variant="primary">
              ساقلاش
            </b-button>
            <b-button class="ml-2" type="reset" variant="danger">
              بىكار قىلىش
            </b-button>
          </b-form>
        </template>
      </portlet>
  </div>
</template>
<script>
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import Portlet from "../../partials/content/Portlet";
  import ImageUploader from "../../partials/layout/_ImageUploader";
  import vcolorpicker from "vcolorpicker";
  import Vue from 'vue';
  import { validationMixin } from "vuelidate";
  import categoryMixin from "@m@/categoryMixin";
  import { required } from "vuelidate/lib/validators";
  Vue.use(vcolorpicker);
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";

  export default {
    name: "categoryForm",
    mixins: [ formBusyMixin, validationMixin, categoryMixin, sweetAlertMixin ],
    props: {
      action: {
        type: String,
        default: "create"
      }
    },
    validations: {
      formData: {
        title:          { required }
      }
    },
    components: { Portlet, ImageUploader },
    computed: {
      portletTitle(){
        let create = "يېڭىدىن تۈر قوشۇش";
        let edit = "تۈر ئۇچۇرىنى تەھرىرلەش";
        return this.action === "create" ? create : edit;
      },
      oldImage(){
        return this.action === 'edit' && this.formData.icon_image || '';
      },
    },
    created() {
      if ( this.action === 'edit' ){
        let id = this.$route.params.id;
        this.findCategory(id)
          .then(({data})=>{
            this.formData = data;
          })
          .catch(()=>{
            return this.$router.replace({name: 'categories.list'});
          })
      }
    },
    data(){
      return {
        formData: {
          title: '',
          background: '',
          icon_image: '',
          is_show: true,
          in_home: false,
          sort: null
        }
      };
    },
    methods: {
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          this.warning('تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ');
          return;
        }
        if ( this.action === 'create' ){
          this.createCategory(this.formData)
            .then(()=>{
              return this.$router.replace({name: 'categories.list'});
            })
        }else if ( this.action === 'edit' ){
          this.updateCategory(this.formData)
            .then(()=>{
              this.success('تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!');
            })
        }
      },
      onReset(){
        return this.$router.replace({name: 'categories.list'});
      },
      onUploadSuccess(response){
        this.formData.icon_image = response.path;
        this.formData.preview = response.url;
      },
      changeBackground(e){
        this.formData.background = e;
      }
    }
  }
</script>
